import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import $ from 'jquery';
import jQuery from 'jquery';

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
    );
  const [loading, setLoading] = useState(false)

  const loginUser = async (username, password, setLoading) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/accounts/token-obtain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken")
      },
      body: JSON.stringify({
          username,
          password
      })
    });
    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      setLoading(false);
      history.push("/");
      toast.success("Login successful");      
    } else {
      if (data.hasOwnProperty("detail")) {
        toast.error(data["detail"].toString())
      }
      if (data.hasOwnProperty("username")) {
        toast.error(`Username: ${data['username'].toString()}`, {position:'bottom-right'});
      }
      if (data.hasOwnProperty("password")) {
        toast.error(`Password: ${data['password'].toString()}`, {position:'bottom-right'});
      }
      setLoading(false);
    };
  };
  
  const registerUser = async (username, email, first_name, last_name, password, password2, setLoading) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/account/user/create-new-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          username,
          email,
          first_name,
          last_name,
          password,
          password2
      })
    });
    const data = await response.json();
    if (response.status === 201) {
       setLoading(false);
       history.push("/blogPosts/account/Login");
       toast.success(`Account for ${username} created`);
    } else {
      if (data.hasOwnProperty("detail")) {
        toast.error(data["detail"].toString())
      }
      if (data.hasOwnProperty("first_name")) {
        toast.error(`First name: ${data['first_name'].toString()}`, {position:'bottom-right'});
      }
      if (data.hasOwnProperty("last_name")) {
        toast.error(`Last name: ${data['last_name'].toString()}`, {position:'bottom-right'});
      }
      if (data.hasOwnProperty("username")) {
        toast.error(`Username: ${data['username'].toString()}`, {position:'bottom-right'});
      }
      if (data.hasOwnProperty("email")) {
        toast.error(`Email address: ${data['email'].toString()}`, {position:'bottom-right'});
      }
      if (data.hasOwnProperty("password")) {
        toast.error(`Password: ${data['password'].toString()}`, {position:'bottom-right'});
      }
      if (data.hasOwnProperty("password2")) {
        toast.error(`Password confirmation: ${data['password2'].toString()}`, {position:'bottom-right'});
      }
      setLoading(false);
    };
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    window.location.reload(true);
    toast.info("Logged out successful");
  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};