import React, { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import { Navbar, Link, Text } from "@nextui-org/react";

import AuthContext from "../context/AuthContext";


function TopNav(props) {

  const { user, logoutUser } = useContext(AuthContext);
  
  const history = useHistory();
  const [homeSection, setHome] = useState('');
  const [aboutSection, setAbout] = useState('');
  const [projectSection, setProject] = useState('');
  const [contactSection, setContact] = useState('');

  const removeCollapse = () => {
      //$('#navCollapse').attr('aria-pressed', 'false');
      //$('#collapse').removeClass("nextui-c-iTPCAT-dusCa-isOpen-true");
      //$('#navCollapse').children().removeClass("nextui-c-gpbAMl-iPooNr-isExpanded-true");
      //$('#collapse').find('li').css({
      //    '--nextui--collapseItemOpacity': '0',
      //    '--nextui--collapseItemTransform': 'matrix(0.97, 0, 0, 1, 0, 20)', 
      //    '--netxui--collapseItemTransition': 'none'
      //})
      //nextui - c - iTPCAT - dusCa - isOpen - true

  }

  useEffect(() => {
    const home = document.getElementById('home');
    const about = document.getElementById('about');
    const project = document.getElementById('projects');
    const contact = document.getElementById('contact');
    setHome(home);
    setAbout(about);
    setProject(project);
    setContact(contact);
  }, []);


  const handleHome = () => {
    homeSection.scrollIntoView();
  }
  const handleAbout = () => {
    const yOffset = -60;
    const y = aboutSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }
  const handleProject = () => {
    const yOffset = -60;
    const y = projectSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
    }

  const handleContact = () => {
    const yOffset = -60;
    const y = contactSection.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  return (
    <Navbar variant={"sticky"} disableShadow disableBlur id='top-nav' css={{zIndex: 2000}}>
        <Navbar.Brand>
          <Text b color="inherit" css={{ fontSize: '2rem', fontWeight: '900' }}>
            ET
          </Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs" variant="underline-rounded" activeColor={'$white'}>
          <div className='h-50'>
            <Navbar.Link id='homeLink' onClick={handleHome}>Home</Navbar.Link>
            <div className='d-flex justify-content-center'>
              <div id='homeL'>
              </div>
            </div>
          </div>
          <div className='h-50'>
            <Navbar.Link id='aboutLink' onClick={handleAbout}>About</Navbar.Link>
            <div className='d-flex justify-content-center'>
              <div id='aboutL'>
              </div>
            </div>
          </div>
          <div className='h-50'>
            <Navbar.Link id='projectsLink' onClick={handleProject}>Projects</Navbar.Link>
            <div className='d-flex justify-content-center'>
              <div id='proL'>
              </div>
            </div>
          </div>
          <div className='h-50'>
            <Navbar.Link id='contactLink' onClick={handleContact}>Contact</Navbar.Link>
            <div className='d-flex justify-content-center'>
              <div id='contactL'>
              </div>
            </div>
          </div>
          <Navbar.Link onClick={(e) => history.push('/blogPosts')}>Blog</Navbar.Link>
          {user ? (
              <>
              <div className='h-50'>
                  <Navbar.Link id='newLink' href="/blogPosts/new">New Post</Navbar.Link>
                  <div className='d-flex justify-content-center'>
                      <div id='newL'>
                      </div>
                  </div>
              </div>
              <div className='h-50'>
                  <Navbar.Link id='projectsLink' href="/blogPosts/project/new">Add Project</Navbar.Link>
                  <div className='d-flex justify-content-center'>
                      <div id='projectsL'>
                      </div>
                  </div>
              </div>
              <div className='h-50'>
                  <Navbar.Link id='mailLink' href="/blogPosts/mail/new">New Mail</Navbar.Link>
                  <div className='d-flex justify-content-center'>
                      <div id='mailL'>
                      </div>
                  </div>
              </div>
              <div className='h-50'>
                  <Navbar.Link id='loginLink' onClick={logoutUser}>Logout</Navbar.Link>
                  <div className='d-flex justify-content-center'>
                      <div id='loginL'>
                      </div>
                  </div>
              </div>
              </>
              ) : (<></>)}
          </Navbar.Content>
        <Navbar.Toggle id="navCollapse" showIn={"xs"} aria-label="toggle navigation"/>

          <Navbar.Collapse id="collapse">
              <Navbar.CollapseItem ><Link href="#home" onClick={removeCollapse} color="inherit" >Home</Link></Navbar.CollapseItem>
              <Navbar.CollapseItem ><Link href="#about" onClick={removeCollapse} color="inherit" >About</Link></Navbar.CollapseItem>
              <Navbar.CollapseItem ><Link href="#projects" onClick={removeCollapse} color="inherit" >Projects</Link></Navbar.CollapseItem>
              <Navbar.CollapseItem ><Link href="#contact" onClick={removeCollapse} color="inherit" >Contact</Link></Navbar.CollapseItem>
              <Navbar.CollapseItem ><Link onClick={(e) => history.push('/blogPosts')} color="inherit" >Blog</Link></Navbar.CollapseItem>
              {user ? (
                  <><Navbar.CollapseItem >
                      <Link href="/blogPosts/new" className="mt-2" color="inherit" >New Post</Link>
                  </Navbar.CollapseItem>
                  <Navbar.CollapseItem >
                      <Link href="/blogPosts/project/new" color="inherit" >Add Project</Link>
                  </Navbar.CollapseItem>
                  <Navbar.CollapseItem >
                      <Link href="/blogPosts/mail/new" color="inherit" >New mail</Link>
                  </Navbar.CollapseItem>
                  <Navbar.CollapseItem >
                      <Link onClick={logoutUser} color="inherit" >Logout</Link>
                  </Navbar.CollapseItem></>
              ) : (<></>)}
          </Navbar.Collapse>
          
    </Navbar>
  );
}


export default TopNav;