import React, { useState } from "react";
import axios from "axios";

import { Typography, Grid, CircularProgress } from "@mui/material";
import { Input, Spacer, Button, Loading } from "@nextui-org/react";

import {
  TiSocialFacebookCircular,
  TiSocialTwitterCircular,
  TiSocialLinkedinCircular,
  TiSocialInstagramCircular,
} from "react-icons/ti";
import { TbArrowRight } from "react-icons/tb";

import { toast } from "react-toastify";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const subscribe = () => {
    let form_data = new FormData();

    form_data.append("email", email);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/blog-posts/mailing/list/new`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setEmail("");
        toast.success("Thank you for been a part of our mailing list");
      })
      .catch((err) => {
        if (err.response.data.hasOwnProperty("detail")) {
          toast.error(err.response.data["detail"].toString());
        }
        if (err.response.data.hasOwnProperty("email")) {
          toast.error(`Email Error: ${err.response.data["email"].toString()}`);
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Spacer y={8} />
      <footer className="footer">
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          <div className="wave" id="wave4"></div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography
              textAlign="left"
              variant="h5"
              color="#fff"
              className="t-center"
            >
              Get updates about new stuff hapenning here
            </Typography>
            <Spacer y={2.5} />
            <Input
              underlined
              size="lg"
              required
              fullWidth
              value={email}
              labelPlaceholder="Email address"
              type="email"
              autoComplete="new-password"
              contentRight={
                <Button
                  auto
                  color="transparent"
                  className="subscribe-button-bg"
                  onClick={subscribe}
                  icon={
                    loading ? (
                      <CircularProgress />
                    ) : (
                      <TbArrowRight className="subscribe-button" />
                    )
                  }
                />
              }
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ul className="social-icon">
              <li className="social-icon__item">
                <a
                  className="social-icon__link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/EmmanuelTobiloba2"
                >
                  <TiSocialFacebookCircular />
                </a>
              </li>
              <li className="social-icon__item">
                <a
                  className="social-icon__link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.twitter.com/Emmantobiloba"
                >
                  <TiSocialTwitterCircular />
                </a>
              </li>
              <li className="social-icon__item">
                <a
                  className="social-icon__link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/emmanuel-tobiloba-058502170"
                >
                  <TiSocialLinkedinCircular />
                </a>
              </li>
              <li className="social-icon__item">
                <a
                  className="social-icon__link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/Emmanuel_great"
                >
                  <TiSocialInstagramCircular />
                </a>
              </li>
            </ul>
            <ul className="menu">
              <li className="menu__item">
                <a className="menu__link" href="/#home">
                  Home
                </a>
              </li>
              <li className="menu__item">
                <a className="menu__link" href="/#about">
                  About
                </a>
              </li>
              <li className="menu__item">
                <a className="menu__link" href="/#projects">
                  Projects
                </a>
              </li>
              <li className="menu__item">
                <a className="menu__link" href="/blogPosts">
                  Blog
                </a>
              </li>
              <li className="menu__item">
                <a className="menu__link" href="/#contact">
                  Contact
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>

        <p className="t-center">
          &copy;{new Date().getFullYear()} Emmanuel Tobiloba | All Rights
          Reserved
        </p>
      </footer>
    </>
  );
};

export default Footer;
