import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import NavBar from "../components/blogNav";
import Footer from "../components/footer.js";
import dayjs from "dayjs";

import { toast } from "react-toastify";

import { Container, Row, Col } from "reactstrap";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";

import {
  Card,
  Row as NRow,
  Text,
  Pagination,
  Loading,
} from "@nextui-org/react";

const Blog = () => {
  const history = useHistory();
  const [posts, setPosts] = useState([]);
  const [postsStatus, setPostsStatus] = useState(false);
  const [postsCount, setPostsCount] = useState(0);
  const [featuredPost, setFeaturedPost] = useState([]);
  const [featuredPostsStatus, setFeaturedPostsStatus] = useState(false);
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState("");
  const [paginationSize, setPaginationSize] = useState("");

  const windowWidth = $(window).innerWidth();

  const pageChange = (page) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/blog-posts/all?page=${page}&page_size=${pageSize}`
        )
        .then((res) => {
          setPosts(res.data.results);
        });
    } catch (error) {
      toast.error("Unable to get posts, Refresh or Try again!");
    }
  };

  useEffect(() => {
    $(document).ready(function () {
      $(window).resize(function () {
        var windowWidth = $(window).innerWidth();
        try {
          if (windowWidth >= 1 && windowWidth <= 599) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/blog-posts/all?page=1&page_size=6`
              )
              .then((res) => {
                setPosts(res.data.results);
                setPages(Math.ceil(res.data.count / res.data.results.length));
                setPageSize(6);
                setPaginationSize("md");
                setPostsCount(res.data.count);
                setPostsStatus(true);
              });
          } else if (windowWidth >= 600 && windowWidth <= 899) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/blog-posts/all?page=1&page_size=9`
              )
              .then((res) => {
                setPosts(res.data.results);
                setPages(Math.ceil(res.data.count / res.data.results.length));
                setPageSize(9);
                setPaginationSize("lg");
                setPostsCount(res.data.count);
                setPostsStatus(true);
              });
          } else if (windowWidth >= 900) {
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/blog-posts/all?page=1&page_size=12`
              )
              .then((res) => {
                setPosts(res.data.results);
                setPages(Math.ceil(res.data.count / res.data.results.length));
                setPageSize(12);
                setPaginationSize("xl");
                setPostsCount(res.data.count);
                setPostsStatus(true);
              });
          }
        } catch (error) {
          toast.error("Unable to get posts, Refresh or Try again!");
          setPostsStatus(false);
        }
      });
      try {
        if (windowWidth >= 1 && windowWidth <= 599) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/blog-posts/all?page=1&page_size=6`
            )
            .then((res) => {
              setPosts(res.data.results);
              setPages(Math.ceil(res.data.count / res.data.results.length));
              setPageSize(6);
              setPaginationSize("md");
              setPostsCount(res.data.count);
              setPostsStatus(true);
            });
        } else if (windowWidth >= 600 && windowWidth <= 899) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/blog-posts/all?page=1&page_size=9`
            )
            .then((res) => {
              setPosts(res.data.results);
              setPages(Math.ceil(res.data.count / res.data.results.length));
              setPageSize(9);
              setPaginationSize("lg");
              setPostsCount(res.data.count);
              setPostsStatus(true);
            });
        } else if (windowWidth >= 900) {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/blog-posts/all?page=1&page_size=12`
            )
            .then((res) => {
              setPosts(res.data.results);
              setPages(Math.ceil(res.data.count / res.data.results.length));
              setPageSize(12);
              setPaginationSize("xl");
              setPostsCount(res.data.count);
              setPostsStatus(true);
            });
        }
      } catch (error) {
        toast.error("Unable to get posts, Refresh or Try again!");
        setPostsStatus(false);
      }
    });

    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/blog-posts/featured-post`)
        .then((res) => {
          setFeaturedPost(res.data);
          setFeaturedPostsStatus(true);
        });
    } catch (error) {
      toast.error("Unable to get posts, Refresh or Try again!");
      setFeaturedPostsStatus(false);
    }
  }, []);

  return (
    <>
      <NavBar />
      {featuredPostsStatus ? (
        <>
          <Container fluid="xxl" className="mt-5 cont-mx">
            <Row>
              <Col lg="6">
                {featuredPostsStatus ? (
                  featuredPost.length > 0 ? (
                    <Card
                      isPressable
                      variant="bordered"
                      onPress={(e) =>
                        history.push(`/blogPosts/${featuredPost[0].slug}`)
                      }
                    >
                      <Card.Body css={{ p: 0 }}>
                        <Card.Image
                          loading="lazy"
                          src={featuredPost[0].backdrop}
                          objectFit="cover"
                          width="100%"
                          height={"22.5rem"}
                          alt={featuredPost[0].title}
                        />
                      </Card.Body>
                      <Card.Footer
                        css={{
                          justifyItems: "flex-start",
                          flexDirection: "column",
                          backgroundColor: "black",
                          color: "white",
                        }}
                      >
                        <Col
                          className="align-self-start overflow-hidden"
                          css={{ textOverflow: "ellipsis" }}
                        >
                          <Typography
                            sx={{ fontWeight: "600", lineHeight: "1.2" }}
                            variant="h4"
                          >
                            {featuredPost[0].title}
                          </Typography>
                          <Typography
                            className="mt-3"
                            variant="h6"
                            color="white"
                            sx={{
                              maxHeight: "5.7rem",
                              height: "5.7rem",
                              textOverflow: "ellipsis",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: featuredPost[0].body,
                            }}
                          ></Typography>
                        </Col>
                        <NRow
                          className="mt-5"
                          wrap="wrap"
                          justify="space-between"
                          align="center"
                        >
                          <Text b css={{ color: "$white" }}>
                            Emmanuel Tobiloba
                          </Text>
                          <Text
                            css={{
                              color: "$accents7",
                              fontWeight: "$semibold",
                              fontSize: "$sm",
                            }}
                          >
                            {dayjs(featuredPost[0].created_on).format(
                              "DD/MM/YY"
                            )}
                          </Text>
                        </NRow>
                      </Card.Footer>
                    </Card>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <h1>No posts yet!</h1>
                    </div>
                  )
                ) : (
                  <Card isPressable variant="bordered">
                    <Card.Body css={{ p: 0 }}>
                      <Skeleton variant="rectangle" height={"22.5rem"} />
                    </Card.Body>
                    <Card.Footer
                      css={{
                        justifyItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Col>
                        <Typography
                          sx={{ fontWeight: "600", lineHeight: "1.2" }}
                          variant="h4"
                        >
                          <Skeleton className="w-100" />
                        </Typography>
                        <Typography
                          className="mt-3"
                          variant="h6"
                          color="initial"
                        >
                          <Skeleton className="w-100" />
                        </Typography>
                        <Typography
                          className="mt-3"
                          variant="h6"
                          color="initial"
                        >
                          <Skeleton className="w-100" />
                        </Typography>
                        <Typography
                          className="mt-3"
                          variant="h6"
                          color="initial"
                        >
                          <Skeleton className="w-100" />
                        </Typography>
                      </Col>
                      <NRow
                        className="mt-5"
                        wrap="wrap"
                        justify="space-between"
                        align="center"
                      >
                        <Text b>
                          <Skeleton />
                        </Text>
                        <Text
                          css={{
                            color: "$accents7",
                            fontWeight: "$semibold",
                            fontSize: "$sm",
                          }}
                        >
                          <Skeleton />
                        </Text>
                      </NRow>
                    </Card.Footer>
                  </Card>
                )}
              </Col>
              <Col lg="6" css={{ maxHeight: "100%" }} className="title-mt">
                <Typography sx={{ fontWeight: "600" }} variant="h4">
                  Featured posts
                </Typography>
                <Divider
                  sx={{
                    opacity: "0.8!important",
                    background: "#000",
                    height: "0.1rem!important",
                  }}
                />
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {featuredPostsStatus ? (
                    featuredPost.length > 1 ? (
                      featuredPost.slice(1, 6).map((post) => {
                        return (
                          <ListItemButton
                            alignItems="flex-start"
                            key={post.id}
                            onClick={() =>
                              history.push(`/blogPosts/${post.slug}`)
                            }
                            sx={{
                              height: "6.5rem",
                              maxHeight: "6.5rem",
                              overflow: "hidden",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt="Emmanuel Tobiloba"
                                src={post.backdrop}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontWeight: 600 }}>
                                  {post.title}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "1rem",
                                    height: "1rem",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  dangerouslySetInnerHTML={{
                                    __html: post.body,
                                  }}
                                ></Typography>
                              }
                            />
                          </ListItemButton>
                        );
                      })
                    ) : (
                      <>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="Emmanuel Tobiloba"
                              src="/static/images/avatar/1.jpg"
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontWeight: 600 }}>
                                <Skeleton />
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="Emmanuel Tobiloba"
                              src="/static/images/avatar/1.jpg"
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontWeight: 600 }}>
                                <Skeleton />
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="Emmanuel Tobiloba"
                              src="/static/images/avatar/1.jpg"
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontWeight: 600 }}>
                                <Skeleton />
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="Emmanuel Tobiloba"
                              src="/static/images/avatar/1.jpg"
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontWeight: 600 }}>
                                <Skeleton />
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar
                              alt="Emmanuel Tobiloba"
                              src="/static/images/avatar/1.jpg"
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontWeight: 600 }}>
                                <Skeleton />
                              </Typography>
                            }
                            secondary={
                              <>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                                <Typography
                                  sx={{
                                    display: "inline",
                                    maxHeight: "2rem",
                                    height: "2rem",
                                    textOverflow: "ellipsis",
                                  }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  <Skeleton />
                                </Typography>
                              </>
                            }
                          />
                        </ListItem>
                      </>
                    )
                  ) : (
                    <>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Emmanuel Tobiloba"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 600 }}>
                              <Skeleton />
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Emmanuel Tobiloba"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 600 }}>
                              <Skeleton />
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Emmanuel Tobiloba"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 600 }}>
                              <Skeleton />
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Emmanuel Tobiloba"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 600 }}>
                              <Skeleton />
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Emmanuel Tobiloba"
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontWeight: 600 }}>
                              <Skeleton />
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                              <Typography
                                sx={{
                                  display: "inline",
                                  maxHeight: "2rem",
                                  height: "2rem",
                                  textOverflow: "ellipsis",
                                }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Skeleton />
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                </List>
              </Col>
            </Row>
            <div className="title-mt">
              <Typography
                className="mt-3"
                variant="h4"
                sx={{ fontWeight: 600 }}
              >
                All Posts
              </Typography>
              <Divider
                className="mb-3"
                sx={{
                  opacity: "0.8!important",
                  background: "#000",
                  height: "0.1rem!important",
                }}
              />
            </div>
            <Grid container className="my-3" spacing={2}>
              {postsStatus ? (
                posts.length > 0 ? (
                  posts.map((post) => {
                    return (
                      <Grid item xs={6} sm={4} md={3} key={post.id}>
                        <Card
                          variant="bordered"
                          css={{ w: "100%" }}
                          isHoverable
                          isPressable
                          onPress={(e) =>
                            history.push(`/blogPosts/${post.slug}`)
                          }
                        >
                          <Card.Header
                            css={{ position: "absolute", zIndex: 1, top: 5 }}
                            className="overlay"
                          >
                            <Col>
                              <Text
                                size={"0.8rem"}
                                weight="medium"
                                className="text-muted"
                              >
                                Published on{" "}
                                {dayjs(post.created_on).format("MMMM DD, YYYY")}
                              </Text>
                              <Text h2 color="black" transform="capitalize">
                                {post.title}
                              </Text>
                            </Col>
                          </Card.Header>
                          <Card.Image
                            loading="lazy"
                            src={post.backdrop}
                            width="100%"
                            // height={}
                            objectFit="cover"
                            alt="Card image background"
                            css={{ aspectRatio: 1 / 1 }}
                          />
                        </Card>
                      </Grid>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <h1>No posts yet!</h1>
                  </div>
                )
              ) : (
                <>
                  <Grid item xs={6} sm={4} md={3}>
                    <Card
                      variant="bordered"
                      css={{ w: "100%", aspectRatio: 1 / 1 }}
                      isHoverable
                    >
                      <Skeleton
                        variant="rectangle"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={6} sm={4} md={3}>
                    <Card
                      variant="bordered"
                      css={{ w: "100%", aspectRatio: 1 / 1 }}
                      isHoverable
                    >
                      <Skeleton
                        variant="rectangle"
                        width={"100%"}
                        height={"100%"}
                      />
                    </Card>
                  </Grid>
                  {windowWidth >= 600 && windowWidth <= 899 ? (
                    <Grid item xs={6} sm={4} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {windowWidth > 899 ? (
                    <Grid item xs={6} sm={4} md={3}>
                      <Card
                        variant="bordered"
                        css={{ w: "100%", aspectRatio: 1 / 1 }}
                        isHoverable
                      >
                        <Skeleton
                          variant="rectangle"
                          width={"100%"}
                          height={"100%"}
                        />
                      </Card>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
            {postsCount > pageSize ? (
              <div className="d-flex align-items-center mt-3 mb-5 justify-content-center">
                <Pagination
                  rounded
                  bordered
                  size={paginationSize}
                  total={pages}
                  initialPage={1}
                  onChange={pageChange}
                />
              </div>
            ) : (
              <></>
            )}
          </Container>
          <Footer />
        </>
      ) : (
        <div>
          <div className="d-flex vh-100 w-100 flex-column align-items-center justify-content-center">
            <Typography
              variant="h1"
              sx={{ zIndex: 90 }}
              className="position-absolute"
            >
              ET
            </Typography>
            <Loading
              type="gradient"
              loadingCss={{ $$loadingSize: "200px", $$loadingBorder: "10px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
