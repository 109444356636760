import React from "react";
import { ToastContainer } from "react-toastify";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";

import Home from "./views/homepage.js";
import Blog from "./views/blog.js";
import NewPost from "./views/newPost";
import NewProject from "./views/newProject";
import NewMail from "./views/newMail";
import BlogPost from "./views/blogPost";
import Login from "./views/login";
import Register from "./views/register";

import "./index.css";
import 'aos/dist/aos.css';  
import 'react-toastify/dist/ReactToastify.css'
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/lib/codemirror.css';
import 'katex/dist/katex.min.css'

import { createTheme, NextUIProvider } from "@nextui-org/react";
import { createTheme as materialTheme, ThemeProvider } from '@mui/material/styles';

if (window.location.origin === process.env.REACT_APP_API_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
} else {
  axios.defaults.baseURL = window.location.origin;
}

const muiTheme = materialTheme({
  pallette: {
    primary: {
      main: '#141414'
    },
    secondary: {
      main: '#ffffff'
    }
  },
  typography: {
    fontFamily: 'Alegreya Sans, sans-serif'
  }
})

const theme = createTheme({
  type: "light",
  theme: {
    colors: {
      // secondaryLight: '#E8759D',
      // secondaryLightHover: '#D24F8B',
      // secondaryLightActive: '#B51F72',
      // secondaryLightContrast: '#820F65',
       secondary: '#ffffff',
      // secondaryBorder:'#9B166D',
      // secondaryBorderHover: '#820F65',
      // secondarySolidHover: '#68095A',
      // secondarySolidContrast: '$white',
      // secondaryShadow: '#9B166D',

      primaryLight: '$black300',
      primaryLightHover: '$black400',
      primaryLightActive: '$black500',
      primaryLightContrast: '$black700',
      primary: '#000000',
      primaryBorder:'$black600',
      primaryBorderHover: '$black700',
      primarySolidHover: '$black800',
      primarySolidContrast: '$white',
      primaryShadow: '$black600',
      link: '$820F65'
    },
  },
});


export default function App() {
  return (
    <Router>
      <ThemeProvider theme={muiTheme}>
        <NextUIProvider theme={theme}>
          <ToastContainer autoClose={6000} draggable={false}
                      closeButton={true} theme='colored' />
          <AuthProvider>
              <Switch>
                <Route path="/blogPosts/account/Login" component={Login}></Route>
                <Route path="/blogPosts/account/Register" component={Register}></Route>
                <PrivateRoute path="/blogPosts/new" component={NewPost}></PrivateRoute>
                <PrivateRoute path="/blogPosts/project/new" component={NewProject}></PrivateRoute>
                <PrivateRoute path="/blogPosts/mail/new" component={NewMail}></PrivateRoute>
                <Route path="/blogPosts/:slug" component={BlogPost}></Route>
                <Route path="/blogPosts" component={Blog}></Route>
                <Route exact path="/" component={Home}></Route>
              </Switch>
          </AuthProvider>
        </NextUIProvider>
      </ThemeProvider>
    </Router>
  )
}