import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import $ from 'jquery';

import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";

import NavBar from '../components/blogNav';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Loading } from '@nextui-org/react';

import {
    Container,
    Row,
    Col,
} from "reactstrap";

import { toast } from 'react-toastify';
import SunEditor from 'suneditor-react';
import plugins from 'suneditor/src/plugins';
import { CodeMirror } from 'codemirror';
import katex from 'katex';
import Footer from "../components/footer.js";



const NewPost = () => {

    const api = useAxios();
    const { user } = useContext(AuthContext);

    const history = useHistory();
    const location = useLocation();

    const Window_height = $(window).outerHeight(true);

    const [title, setTitle] = useState('');
    const [backdrop, setBackdrop] = useState(null);
    const [body, setBody] = useState('');

    const [loading, setLoading] = useState(false);


    const editorChange = (content) => {
        if (location.state) {
            location.state.body = null;
        }
        setBody(content);
    }

    const titleChange = (e) => {
        if (location.state) {
            location.state.title = null;
        }
        setTitle(e.target.value);
    }

    const backdropChange = (e) => {
        if (location.state) {
            location.state.backdrop = null;
        }
        setBackdrop(e.target.files[0])
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setLoading(true);

        if (location.state) {
            if (location.state.title) {
                setTitle(location.state.title);
            }
            if (location.state.body) {
                setBody(location.state.body);
            }
        }

        let form_data = new FormData();

        form_data.append('author', 1);
        if (backdrop) {
            form_data.append('backdrop', backdrop, backdrop.name);
        }
        if (title) {
            form_data.append('title', title);
        }
        if (body) {
            form_data.append('body', body);
        }

        api.patch(`${process.env.REACT_APP_API_URL}/blog-posts/${location.state.slug}/update`, form_data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                setLoading(false);
                history.push(`/blogPosts/${res.data.slug}`);
                toast.success("Post updated successfuly");
            })
            .catch(err => {
                if (err.response.data.hasOwnProperty("detail")) {
                    toast.error(err.response.data['detail'].toString())
                }
                if (err.response.data.hasOwnProperty("title")) {
                    toast.error(`Title Error: ${err.response.data['title'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("backdrop")) {
                    toast.error(`Backdrop Error: ${err.response.data['backdrop'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("body")) {
                    toast.error(`Body Error: ${err.response.data['body'].toString()}`)
                }
                setLoading(false)
            })
    }

    const handleCreate = (e) => {
        e.preventDefault();
        setLoading(true);

        let form_data = new FormData();

        form_data.append('author', 1);
        if (backdrop) {
            form_data.append('backdrop', backdrop, backdrop.name);
        }
        form_data.append('title', title);
        form_data.append('body', body);

        api.post(`${process.env.REACT_APP_API_URL}/blog-posts/new`, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
            .then(res => {
                setLoading(false);
                history.push(`/blogPosts/${res.data.slug}`);
                toast.success("Post created successfuly");
            })
            .catch(err => {
                if (err.response.data.hasOwnProperty("detail")) {
                    toast.error(err.response.data['detail'].toString())
                }
                if (err.response.data.hasOwnProperty("title")) {
                    toast.error(`Title Error: ${err.response.data['title'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("backdrop")) {
                    toast.error(`Backdrop Error: ${err.response.data['backdrop'].toString()}`)
                }
                if (err.response.data.hasOwnProperty("body")) {
                    toast.error(`Body Error: ${err.response.data['body'].toString()}`)
                }
                setLoading(false)
            })
    };


    return (
        <>
            <NavBar />
            <Container fluid="xxl" className="mt-5">
                <Col>
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        {location.state ? `Edit post` : 'Create new post'}
                    </Typography>
                    <Divider className='mb-3' sx={{
                        opacity: '0.8!important', background: '#000',
                        height: '0.1rem!important'
                    }} />
                    
                    <Row className="mt-3">
                        {location.state ?
                            <TextField fullWidth value={location.state.title} label="Title" variant="outlined"
                                onChange={titleChange} />
                        : 
                             <TextField fullWidth label="Title" variant="outlined"
                        onChange={titleChange} />
                         }
                    </Row>
                    <Row className="my-3">
                        <TextField fullWidth 
                            label="Backdrop" variant="outlined" type="file"
                            onChange={backdropChange} accept="image/*" />
                    </Row>
                    <Row>
                        <SunEditor onChange={editorChange} setOptions={{
                            "mode": "classic",
                            "plugins": plugins,
                            "rtl": false,
                            "codeMirror": CodeMirror,
                            "katex": katex,
                            "value": `${location.state ? location.state.body ? location.state.body : '' : ''}`,
                            "defaultStyle": "font-family: 'Alegreya Sans', sans-serif; font-size: 20px;",
                            "tabDisable": false,
                            "charCounter": true,
                            "resizingBar": false,
                            "height": "300",
                            "resizeEnable": false,
                            "maxHeight": `${Window_height}`,
                            "videoFileInput": true,
                            "buttonList": [
                                ["undo", "redo"],
                                ["font", "fontSize", "formatBlock", "paragraphStyle", "blockquote"],
                                ["bold", "underline", "italic", "strike", "subscript", "superscript"],
                                ["fontColor", "hiliteColor", "textStyle", "removeFormat"],
                                ["outdent", "indent", "align", "lineHeight"],
                                ["horizontalRule", "list", "table"],
                                ["math", "codeView", "showBlocks"],
                                ["link", "image", "video", "audio"],
                                ["fullScreen", "preview", "print", "save"]
                            ],
                        }} />
                    </Row>
                    {user ? (
                        <Stack className="j-center w-100" direction={'row'} spacing={2}>
                            <Button disabled={loading} onClick={location.state ? handleUpdate : handleCreate} variant="contained"
                                className="cv-button mt-3" color="primary">
                                {loading ? <Loading type="gradient" /> : location.state ? 'Edit post' : 'Create post'}
                            </Button>
                        </Stack>
                    ) : (<></>)}
                    
                </Col>
            </Container>
            <Footer />
        </>
    )
}

export default NewPost